const tableFields = [
  {
    physical_props: [
      {
        sortField: "ph",
        title: "pH"
      },
      {
        sortField: "specific_conduct_ms_cm",
        title: "Conductivity  [uS/cm]"
      }
    ],
    alkalinities: [
      {
        sortField: "total_alkalinity_mg_l_ca_co3",
        title: "Alcalinity [mg/l CaCO3]"
      }
    ],
    oxygen_regimes: [
      {
        sortField: "dissolved_oxygen_mg_l_o2",
        title: "Dissolved Oxygen [mg O2/l]"
      },
      {
        sortField: "bod5_mg_l_o2",
        title: "BOD5 [mg O2/l]"
      },
      {
        sortField: "ip_cod_kmno4_mg_l_o2",
        title: "COD-KMnO4 [mg O2/l]"
      }
    ],
    nutrients: [
      {
        sortField: "nitrogen_ammonia_mg_l_n",
        title: "NH4 [mg N/l]"
      },
      {
        sortField: "nitrogen_nitrite_mg_l_n",
        title: "NO2 [mg N/l]"
      },
      {
        sortField: "nitrogen_nitrate_mg_l_n",
        title: "NO3 [mg N/l]"
      },
      {
        sortField: "phosphate_mg_l_PO43",
        title: "Phosphate PO4  [mg PO4/l]"
      }
    ]
  }
];
export default tableFields;
