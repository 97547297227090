export default {
  inputData: state => state.inputData,
  year: state => state.year,
  isNavOpen: state => state.isNavOpen,
  loadingScreen: state => state.loadingScreen,
  chartData: state => state.chartData,
  chartOptions: state => state.chartOptions,
  categories: state => state.categories,
  playerReady: state => state.playerReady,
  logoReady: state => state.logoReady,
  stations: state => state.stations,
  years: state => state.years,
  reports: state => state.reports,
  reports_categories: state => state.reports_categories,
  months: state => state.months,
  language: state => state.language
};
