export default {
  inputData: {},
  year: null,
  isNavOpen: false,
  loadingScreen: true,
  categories: null,
  stations: null,
  playerReady: false,
  logoReady: false,
  years: null,
  months: null,
  reports: null,
  reports_categories: null,
  language: "MK",
  chartData: {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    datasets: [
      {
        label: "Data One",
        backgroundColor: null,
        data: [5, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
      }
    ]
  },
  chartOptions: {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "rgba(159,129,129, 0.1)"
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1.5,
            min: 0,
            max: 10.5,
            padding: 20
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgba(159,129,129, 0.1)"
          }
        }
      ]
    }
  }
};
