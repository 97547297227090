export const mobileMenuEn = [
  {
    name: "Water Quality",
    tooltip: "See data",
    href: "table-section"
  },
  {
    name: "Charts",
    tooltip: "See Bar charts",
    href: "first-section"
  },
  {
    name: "Reports",
    tooltip: "See reports",
    href: "reports-section"
  },
  {
    name: "Login",
    tooltip: "Login to internal app",
    href: "https://uhmr.flexicms.tech"
  }
];

export const mobileMenuMk = [
  {
    name: "Квалитет на води",
    tooltip: "Види ги податоците",
    href: "table-section"
  },
  {
    name: "Инфографици",
    tooltip: "Види инфографици",
    href: "first-section"
  },
  {
    name: "Извештаи",
    tooltip: "Види извештаи",
    href: "reports-section"
  },
  {
    name: "Најава",
    tooltip: "Најави се",
    href: "https://uhmr.flexicms.tech"
  }
];

export const mobileMenuAl = [
  {
    name: "Cilësia e ujit",
    tooltip: "Shihni të dhënat",
    href: "table-section"
  },
  {
    name: "Infografike",
    tooltip: "Shikoni infografitë",
    href: "first-section"
  },
  {
    name: "Raportet",
    tooltip: "Shinhi raportet",
    href: "reports-section"
  },
  {
    name: "Hyrja",
    tooltip: "Login app brendshëm",
    href: "https://uhmr.flexicms.tech"
  }
];
