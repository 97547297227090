import client from "api-client";

export default {
  fetchCharts({ commit }) {
    return client.fetchCharts().then(data => {
      commit("setCharts", data.charts.stations);
      commit("setCategories", data.categories);
      commit("setStations", data.stations);
      commit("setYears", data.years);
      commit("setMonths", data.months);
      commit("setReports", data.reports);
      commit("setReportsCategories", data.reports_categories.reverse());
    });
  },
  setCharts({ commit }, payload) {
    commit("setCharts", payload);
  },
  fetchData({ state, commit }) {
    return client.fetchData(state.language).then(data => {
      commit("setData", data);
    });
  },
  setPlayer({ commit }, payload) {
    commit("setPlayer", payload);
  },
  setYear({ commit }) {
    commit("setYear");
  },
  setBurger({ commit }) {
    commit("updateBurger");
  },
  updateStationsCharts({ commit }, payload) {
    return client.updateStationsCharts(payload).then(data => {
      commit("setCharts", data.charts);
    });
  },
  updateAvgCharts({ commit }, payload) {
    return client.updateAvgCharts(payload).then(data => {
      commit("setCharts", data.charts);
    });
  },
  setLanguage({ commit }, language) {
    client.fetchData(language).then(data => {
      commit("setData", data);
    });
    commit("setLanguage", language);
  }
};
