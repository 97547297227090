<template>
  <div id="app">
    <!-- Entering, reload site transition -->
    <transition name="fade" mode="out-in">
      <div v-if="!loadingScreen" key="content">
        <!-- LOGO & Header -->
        <flexi-header
          :inputData="inputData.navigationPages"
          :isNavOpen="isNavOpen"
          @toggle-burger="setBurger"
          :isMenuLinks="false"
          v-if="logoReady"
        >
          <template v-slot:logo>
            <router-link :to="'/'">
              <span v-if="language == 'EN'" class="header-logo">
                Hydrometeorological Service <br />
                Republic of North Macedonia
              </span>
              <span v-else-if="language == 'MK'" class="header-logo">
                Управа за хидрометеоролошки работи Република Северна Македонија
              </span>
              <span v-else-if="language == 'AL'" class="header-logo">
                Drejtoria për punë hidrometeorologjike Republika e Maqedonisë së
                Veriut
              </span>
            </router-link>
          </template>
          <template v-slot:flexi-burger>
            <div class="header-languages">
              <span
                class="header-languages__item"
                v-text="'EN'"
                @click="changeLanguage('EN')"
              />
              <span class="header-languages__cross" v-text="' / '" />
              <span
                class="header-languages__item"
                v-text="'MK'"
                @click="changeLanguage('MK')"
              />
              <span class="header-languages__cross" v-text="' / '" />
              <span
                class="header-languages__item"
                v-text="'AL'"
                @click="changeLanguage('AL')"
              />
            </div>
            <flexi-burger @toggle-burger="setBurger" :isNavOpen="isNavOpen" />
          </template>
          <template v-slot:flexi-bar>
            <flexi-bar
              @toggle-burger="setBurger"
              :inputData="mobileMenu"
              :isNavOpen="isNavOpen"
            >
              <template v-slot:link="{ value }">
                <a
                  v-if="
                    value.name === 'Login' ||
                      value.name === 'Најава' ||
                      value.name === 'Hyrja'
                  "
                  :href="value.href"
                  :target="'_blank'"
                >
                  <span v-text="value.name" />
                </a>
                <span
                  v-else
                  v-text="value.name"
                  @click="smoothScroll(value.href)"
                />
              </template>
            </flexi-bar>
          </template>
        </flexi-header>
        <!-- Navigation -->
        <flexi-nav v-if="logoReady" :inputData="inputData.navigationPages">
          <template v-slot:items="slotProps">
            <a
              v-if="
                slotProps.inputData.name === 'Login' ||
                  slotProps.inputData.name === 'Најава' ||
                  slotProps.inputData.name === 'Hyrja'
              "
              :href="slotProps.inputData.href"
              target="_blank'"
              v-tooltip.bottom-center="slotProps.inputData.tooltip"
            >
              <span v-text="slotProps.inputData.name" />
            </a>
            <span
              v-else
              v-text="slotProps.inputData.name"
              @click="smoothScroll(slotProps.inputData.href)"
              v-tooltip.bottom-center="slotProps.inputData.tooltip"
            />
            <div class="header-languages">
              <span
                class="header-languages__item"
                v-text="'MK'"
                @click="changeLanguage('MK')"
              />
              <span class="header-languages__cross" v-text="' / '" />
              <span
                class="header-languages__item"
                v-text="'AL'"
                @click="changeLanguage('AL')"
              />
              <span class="header-languages__cross" v-text="' / '" />
              <span
                class="header-languages__item"
                v-text="'EN'"
                @click="changeLanguage('EN')"
              />
            </div>
          </template>
        </flexi-nav>
        <!-- Router View + Transition -->
        <vue-page-transition name="overlay-up-down">
          <router-view />
        </vue-page-transition>
        <!-- Footer -->
      </div>
      <!-- Spinner on enterin or reload -->
      <flexi-splash v-else key="content2">
        <template v-slot:spinner>
          <semipolar-spinner
            :animation-duration="2000"
            :size="105"
            color="#fff"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-duration="700"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
          />
        </template>
      </flexi-splash>
    </transition>
    <!-- Ending entering, reload site transition -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  mobileMenuEn,
  mobileMenuMk,
  mobileMenuAl
} from "./static/data/mobileMenu";

export default {
  computed: {
    ...mapGetters([
      "inputData",
      "isNavOpen",
      "loadingScreen",
      "logoReady",
      "year",
      "language"
    ]),
    mobileMenu() {
      if (this.language === "EN") {
        return mobileMenuEn;
      } else if (this.language === "MK") {
        return mobileMenuMk;
      } else {
        return mobileMenuAl;
      }
    }
  },
  created() {
    this.fetchCharts();
    this.fetchData();
    this.setYear();
  },
  methods: {
    ...mapActions([
      "fetchCharts",
      "fetchData",
      "setBurger",
      "setYear",
      "setLanguage"
    ])
  }
};
</script>
<style lang="scss">
@import "assets/scss/application";
.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom,
.overlay-up-down {
  z-index: 10003 !important;
}
</style>
