const apiUrl = process.env.VUE_APP_API_URL;
const token = process.env.VUE_APP_SECRET;
const tokenMk = process.env.VUE_APP_SECRET_MK;
const tokenAl = process.env.VUE_APP_SECRET_AL;
const chartApiUrl = process.env.VUE_APP_CHARTS_URL;
const chartApiToken = process.env.VUE_APP_CHARTS_SECRET;

export default {
  fetchData(language) {
    return new Promise(resolve => {
      var selectedToken;
      if (language === "EN") {
        selectedToken = token;
      } else if (language === "MK") {
        selectedToken = tokenMk;
      } else {
        selectedToken = tokenAl;
      }
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });
      xhr.open("POST", `${apiUrl}`);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("Authorization", `Bearer ${selectedToken}`);
      xhr.setRequestHeader("Accept", "*/*");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send();
    });
  },
  fetchCharts() {
    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });

      xhr.open("POST", `${chartApiUrl + "/get"}`);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("Authorization", `Bearer ${chartApiToken}`);
      xhr.setRequestHeader("Accept", "*/*");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send();
    });
  },
  updateStationsCharts(payload) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${chartApiToken}`);
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var data = {
        form: payload
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow"
      };

      fetch(`${chartApiUrl}/update/stations-charts`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  updateAvgCharts(payload) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${chartApiToken}`);
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var data = {
        form: payload
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: "follow"
      };

      fetch(`${chartApiUrl}/update/average-charts`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  }
};
