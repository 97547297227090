export default {
  setCharts(state, payload) {
    state.chartData.labels = payload.label;
    state.chartData.datasets[0].data = payload.data;
    state.chartData.datasets[0].label = payload.parameter;
    state.chartData.datasets[0].backgroundColor = payload.backgroundColor;
    state.chartOptions.scales.yAxes[0].ticks.min =
      Math.min(...state.chartData.datasets[0].data) < 0
        ? Math.min(...state.chartData.datasets[0].data)
        : 0;

    if (Math.max(...state.chartData.datasets[0].data) > 1) {
      state.chartOptions.scales.yAxes[0].ticks.max =
        Math.max(...state.chartData.datasets[0].data) + 2;
    } else {
      state.chartOptions.scales.yAxes[0].ticks.max = Math.max(
        ...state.chartData.datasets[0].data
      );
    }

    if (Math.max(...state.chartData.datasets[0].data) <= 3) {
      state.chartOptions.scales.yAxes[0].ticks.stepSize = 0.5;
    } else if (
      Math.max(...state.chartData.datasets[0].data) > 3 &&
      Math.max(...state.chartData.datasets[0].data) <= 15
    ) {
      state.chartOptions.scales.yAxes[0].ticks.stepSize = 1.5;
    } else {
      state.chartOptions.scales.yAxes[0].ticks.stepSize = 15;
    }
  },
  setCategories(state, payload) {
    state.categories = payload;
  },
  setPlayer(state, payload) {
    state.playerReady = payload;
    setTimeout(() => {
      state.logoReady = true;
    }, 820);
  },
  setStations(state, payload) {
    state.stations = payload;
  },
  setReports(state, payload) {
    state.reports = payload;
  },
  setReportsCategories(state, payload) {
    state.reports_categories = payload;
  },
  setYears(state, payload) {
    state.years = payload;
  },
  setMonths(state, payload) {
    state.months = payload;
  },
  setData(state, data) {
    state.inputData = data;
    setTimeout(() => {
      state.loadingScreen = false;
    }, 800);
  },
  setYear(state) {
    state.year = new Date().getFullYear();
  },
  updateBurger: state => {
    state.isNavOpen = !state.isNavOpen;
  },
  setLanguage(state, language) {
    state.language = language;
  }
};
