import Vue from "vue";
import { gsap, TimelineMax, Power2 } from "gsap";

gsap.registerPlugin(TimelineMax, Power2);

Vue.mixin({
  methods: {
    animateTopCard() {
      const top_card_title = document.querySelector("#title");
      const top_card_subtitle = document.querySelector("#subtitle");
      const btn = document.querySelector(".advanced-button__wrapper");

      const tl = new TimelineMax();
      tl.fromTo(
        top_card_title,
        { opacity: 0, x: "100px" },
        { opacity: 1, x: "0px", ease: Power2.easeInOut, duration: 1 },
        "-=0.25"
      )
        .fromTo(
          top_card_subtitle,
          { opacity: 0, top: "100px" },
          { opacity: 1, top: "0px", ease: Power2.easeInOut, duration: 0.75 },
          "-=0.25"
        )
        .fromTo(
          btn,
          { opacity: 0, top: "-150px" },
          { opacity: 1, top: "0px", ease: Power2.easeInOut, duration: 1 }
        );
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    goToRouteFromObject(data) {
      this.$router.push(data.buttonLink);
    },
    smoothScroll(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    changeLanguage(language) {
      this.setLanguage(language);
    }
  }
});
