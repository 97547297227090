<template>
  <div class="flexi-container">
    <!-- Video  -->
    <div class="flexi-video">
      <vimeo-player
        v-show="!isMobile && playerReady"
        ref="player"
        class="flexi-video__player"
        :controls="false"
        :video-id="'563222823'"
        :autoplay="true"
        :loop="true"
        :options="options"
        @ready="onReady"
      />
      <div v-show="isMobile" class="flexi-video__image" />
      <div v-show="playerReady && hidebarReady" class="flexi-video__hide-bar" />
    </div>

    <!-- Top Card  -->
    <flexi-top-card
      v-if="inputData.homePage && logoReady && topCardready"
      :inputData="inputData.homePage.top_card"
      @button-clicked="smoothScroll"
    >
      <template v-slot:title="slotProps">
        <h1 id="title" class="top-card__title">
          <span
            class="top-card__title-item"
            v-text="slotProps.inputData.title"
          />
        </h1>
      </template>
      <template v-slot:subTitle="slotProps">
        <h2 id="subtitle" class="top-card__sub-title">
          <span
            class="top-card__sub-title-item"
            v-text="slotProps.inputData.subTitle"
          />
        </h2>
      </template>
      <template v-slot:button>
        <div class="advanced-button">
          <div class="advanced-button__wrapper">
            <a @click="smoothScroll('table-section')">
              <span class="arrow" />
            </a>
          </div>
        </div>
      </template>
    </flexi-top-card>

    <!-- Go To TOP -->
    <flexi-visible when-hidden="#top-card" v-if="logoReady">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- Table Section -->
    <flexi-wrapper
      v-if="inputData.homePage && logoReady"
      :inputData="inputData.homePage.sections[1]"
      @button-clicked="goToRoute"
    >
      <template v-slot:content="slotProps">
        <div class="selector-wrapper selector-wrapper__header">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="table" size="1x" />
            <label
              class="title"
              v-text="
                language == 'MK'
                  ? 'Избери табела'
                  : language == 'AL'
                  ? 'Zgjidhni një tabelë'
                  : language == 'EN'
                  ? 'Select a table'
                  : 'Select a table'
              "
            />
          </div>
          <div
            class="selector-wrapper__button"
            :class="{ active: selectedTable === 'Basic' }"
            v-text="
              language == 'MK'
                ? 'Основни податоци'
                : language == 'AL'
                ? 'Informacione themelore'
                : language == 'EN'
                ? 'Basic informations'
                : 'Basic informations'
            "
            @click="changeSelectedTable('Basic')"
          />
          <div
            class="selector-wrapper__button"
            :class="{ active: selectedTable === 'Heavy metals' }"
            v-text="
              language == 'MK'
                ? 'Метали'
                : language == 'AL'
                ? 'Metale'
                : language == 'EN'
                ? 'Мetals'
                : 'Мetals'
            "
            @click="changeSelectedTable('Heavy metals')"
          />
        </div>
        <div class="selector-wrapper">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            <label class="title">{{ slotProps.inputData.headerTitle }}</label>
          </div>
          <div class="selector-wrapper__item">
            <label class="title" v-text="slotProps.inputData.headerSubtitle" />
            <v-select
              v-if="sortedYears"
              v-model="selectedYear"
              :options="sortedYears"
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="slotProps.inputData.headerDescription"
            />
            <v-select
              v-if="months"
              v-model="selectedMonth"
              :options="months"
              :reduce="name => name.name"
              :selectable="option => tableType == 'data-per-measure-point'"
              :class="{
                'disabled-selector': tableType !== 'data-per-measure-point'
              }"
              :label="
                language == 'MK'
                  ? 'name_mk'
                  : language == 'AL'
                  ? 'name_al'
                  : language == 'EN'
                  ? 'name'
                  : 'name'
              "
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonText
            }}</label>
            <v-select
              v-if="stations"
              v-model="form.station_id"
              :options="stations"
              :reduce="name => name.id"
              :selectable="option => tableType == 'per-months'"
              :class="{ 'disabled-selector': tableType !== 'per-months' }"
              :label="
                language == 'MK'
                  ? 'name_mk'
                  : language == 'EN'
                  ? 'name'
                  : 'name_al'
              "
              class="chart-chooser"
            >
            </v-select>
          </div>

          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonLink
            }}</label>
            <div v-if="selectedTable === 'Basic'" class="legend">
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getHighText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getGoodText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getMediumText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getPoorText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getBadText()" />
              </div>
            </div>
            <div v-else class="legend">
              <div class="legend__item">
                <div class="color green" />
                <div class="description" v-text="getHighGoodText()" />
              </div>
              <div class="legend__item">
                <div class="color orange" />
                <div class="description" v-text="getMediumPoorText()" />
              </div>
              <div class="legend__item">
                <div class="color red" />
                <div class="description" v-text="getBadText()" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedTable === 'Basic'" class="bar-chart">
          <div class="bar-chart__header">
            <div class="icon">
              <font-awesome-icon icon="chart-bar" size="1x" />
              <span class="station" v-text="selectedYear" />
              <span
                class="station"
                v-if="tableType == 'data-per-measure-point'"
                v-text="getMonthName(selectedMonth)"
              />
              <span
                v-if="tableType == 'per-months'"
                class="station"
                v-text="getStationName(form.station_id)"
              />
              <span
                v-else
                class="station"
                v-text="slotProps.inputData.contentTitle"
              />
            </div>
            <div class="menu">
              <div
                @click="changeTableType('data-per-measure-point')"
                class="menu__item"
                :class="{ active: tableType === 'data-per-measure-point' }"
              >
                {{ slotProps.inputData.contentLine }}
              </div>
              <div
                @click="changeTableType('per-months')"
                class="menu__item"
                :class="{ active: tableType === 'per-months' }"
              >
                {{ slotProps.inputData.buttonText }}
              </div>
              <div
                @click="changeTableType('average-yearly')"
                class="menu__item"
                :class="{ active: tableType === 'average-yearly' }"
              >
                {{ slotProps.inputData.buttonLink }}
              </div>
            </div>
          </div>
          <div
            v-if="tableType === 'data-per-measure-point'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr>
                <th v-text="getRiverText()" />
                <th v-text="getMPointText()" />
                <th v-text="getMonthText()" />
                <th v-text="'pH'" />
                <th v-text="'Conductivity  [uS/cm]'" />
                <th v-text="'Alcalinity [mg/l CaCO3]'" />
                <th v-text="'Dissolved Oxygen [mg O2/l]'" />
                <th v-text="'BOD5 [mg O2/l]'" />
                <th v-text="'COD-KMnO4 [mg O2/l]'" />
                <th v-text="'NH4 [mg N/l]'" />
                <th v-text="'NO2 [mg N/l]'" />
                <th v-text="'NO3 [mg N/l]'" />
                <th v-text="'Phosphate PO4  [mg PO4/l]'" />
              </tr>
              <tr
                v-for="(station, key) in stations"
                :key="key"
                @click="selectStation(station)"
                v-show="station.datas.length > 0"
              >
                <td
                  v-text="
                    language == 'MK'
                      ? station.rivers[0].name_mk
                      : language == 'EN'
                      ? station.rivers[0].name
                      : station.rivers[0].name
                  "
                />

                <td
                  v-text="
                    language == 'MK'
                      ? station.name_mk
                      : language == 'EN'
                      ? station.name
                      : station.name_al
                  "
                />
                <td
                  v-for="(value, mKey) in station.datas"
                  :key="'month' + mKey"
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="
                    language == 'MK'
                      ? value.months.name_mk
                      : language == 'AL'
                      ? value.months.name_al
                      : language == 'EN'
                      ? value.months.name
                      : value.months.name
                  "
                />
                <td
                  v-for="(value, ppKey) in station.datas"
                  :key="'pp' + ppKey"
                  :class="getClassName('ph', value.physical_props.ph)"
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.physical_props.ph"
                />
                <td
                  v-for="(value, conductKey) in station.datas"
                  :key="'conuduct' + conductKey"
                  :class="
                    getClassName(
                      'specific_conduct_ms_cm',
                      value.physical_props.specific_conduct_ms_cm
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.physical_props.specific_conduct_ms_cm"
                />
                <td
                  v-for="(value, alKey) in station.datas"
                  :key="'al' + alKey"
                  :class="
                    getClassName(
                      'total_alkalinity_mg_l_ca_co3',
                      value.alkalinities.total_alkalinity_mg_l_ca_co3
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="
                    value.alkalinities.total_alkalinity_mg_l_ca_co3.toFixed(0)
                  "
                />
                <td
                  v-for="(value, oxyKey) in station.datas"
                  :key="'oxy' + oxyKey"
                  :class="
                    getClassName(
                      'dissolved_oxygen_mg_l_o2',
                      value.oxygen_regimes.dissolved_oxygen_mg_l_o2
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="
                    value.oxygen_regimes.dissolved_oxygen_mg_l_o2.toFixed(2)
                  "
                />
                <td
                  v-for="(value, bodKey) in station.datas"
                  :key="'bod' + bodKey"
                  :class="
                    getClassName(
                      'bod5_mg_l_o2',
                      value.oxygen_regimes.bod5_mg_l_o2
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.oxygen_regimes.bod5_mg_l_o2.toFixed(2)"
                />
                <td
                  v-for="(value, ipKey) in station.datas"
                  :key="'ip' + ipKey"
                  :class="
                    getClassName(
                      'ip_cod_kmno4_mg_l_o2',
                      value.oxygen_regimes.ip_cod_kmno4_mg_l_o2
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.oxygen_regimes.ip_cod_kmno4_mg_l_o2.toFixed(2)"
                />
                <td
                  v-for="(value, nKey) in station.datas"
                  :key="'n' + nKey"
                  :class="
                    getClassName(
                      'nitrogen_ammonia_mg_l_n',
                      value.nutrients.nitrogen_ammonia_mg_l_n
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.nutrients.nitrogen_ammonia_mg_l_n.toFixed(4)"
                />
                <td
                  v-for="(value, nitKey) in station.datas"
                  :key="'nit' + nitKey"
                  :class="
                    getClassName(
                      'nitrogen_nitrite_mg_l_n',
                      value.nutrients.nitrogen_nitrite_mg_l_n
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.nutrients.nitrogen_nitrite_mg_l_n.toFixed(4)"
                />
                <td
                  v-for="(value, nitmgKey) in station.datas"
                  :key="'nitmg' + nitmgKey"
                  :class="
                    getClassName(
                      'nitrogen_nitrate_mg_l_n',
                      value.nutrients.nitrogen_nitrate_mg_l_n
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.nutrients.nitrogen_nitrate_mg_l_n.toFixed(4)"
                />
                <td
                  v-for="(value, phosKey) in station.datas"
                  :key="'phos' + phosKey"
                  :class="
                    getClassName(
                      'phosphate_mg_l_PO43',
                      value.nutrients.phosphate_mg_l_PO43
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.nutrients.phosphate_mg_l_PO43.toFixed(4)"
                />
              </tr>
            </table>
          </div>
          <div
            v-else-if="tableType === 'average-yearly'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr>
                <th v-text="getRiverText()" />
                <th v-text="getMPointText()" />
                <th v-text="'pH'" />
                <th v-text="'Conductivity  [uS/cm]'" />
                <th v-text="'Alcalinity [mg/l CaCO3]'" />
                <th v-text="'Dissolved Oxygen [mg O2/l]'" />
                <th v-text="'BOD5 [mg O2/l]'" />
                <th v-text="'COD-KMnO4 [mg O2/l]'" />
                <th v-text="'NH4 [mg N/l]'" />
                <th v-text="'NO2 [mg N/l]'" />
                <th v-text="'NO3 [mg N/l]'" />
                <th v-text="'Phosphate PO4  [mg PO4/l]'" />
                <th
                  v-text="
                    language == 'MK'
                      ? 'Општ Физич.-Хем. Статус'
                      : language == 'EN'
                      ? 'General Phys.-Chemic. Status'
                      : 'General Phys.-Chemic. Status'
                  "
                />
              </tr>
              <tr v-for="(station, key) in stations" :key="key">
                <td
                  v-text="
                    language == 'MK'
                      ? station.rivers[0].name_mk
                      : language == 'EN'
                      ? station.rivers[0].name
                      : station.rivers[0].name
                  "
                />
                <td
                  v-text="
                    language == 'MK'
                      ? station.name_mk
                      : language == 'EN'
                      ? station.name
                      : station.name_al
                  "
                />
                <td
                  v-text="getPhAvg(station.datas)"
                  :class="getClassName('ph', getPhAvg(station.datas))"
                />
                <td
                  v-text="getConductAvg(station.datas)"
                  :class="
                    getClassName(
                      'specific_conduct_ms_cm',
                      getConductAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getAlcAvg(station.datas)"
                  :class="
                    getClassName(
                      'total_alkalinity_mg_l_ca_co3',
                      getAlcAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getDysolvedAvg(station.datas)"
                  :class="
                    getClassName(
                      'dissolved_oxygen_mg_l_o2',
                      getDysolvedAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getBodAvg(station.datas)"
                  :class="
                    getClassName('bod5_mg_l_o2', getBodAvg(station.datas))
                  "
                />
                <td
                  v-text="getCodAvg(station.datas)"
                  :class="
                    getClassName(
                      'ip_cod_kmno4_mg_l_o2',
                      getCodAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getNitAmonAvg(station.datas)"
                  :class="
                    getClassName(
                      'nitrogen_ammonia_mg_l_n',
                      getNitAmonAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getNitroNitAvg(station.datas)"
                  :class="
                    getClassName(
                      'nitrogen_nitrite_mg_l_n',
                      getNitroNitAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getNitroNitrateAvg(station.datas)"
                  :class="
                    getClassName(
                      'nitrogen_nitrate_mg_l_n',
                      getNitroNitrateAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getPhosAvg(station.datas)"
                  :class="
                    getClassName(
                      'phosphate_mg_l_PO43',
                      getPhosAvg(station.datas)
                    )
                  "
                />
                <td
                  :class="
                    getPhysChemStatusClass(
                      getClassName('ph', getPhAvg(station.datas)),
                      getClassName(
                        'specific_conduct_ms_cm',
                        getConductAvg(station.datas)
                      ),
                      getClassName(
                        'total_alkalinity_mg_l_ca_co3',
                        getAlcAvg(station.datas)
                      ),
                      getClassName(
                        'dissolved_oxygen_mg_l_o2',
                        getDysolvedAvg(station.datas)
                      ),
                      getClassName('bod5_mg_l_o2', getBodAvg(station.datas)),
                      getClassName(
                        'ip_cod_kmno4_mg_l_o2',
                        getCodAvg(station.datas)
                      ),
                      getClassName(
                        'nitrogen_ammonia_mg_l_n',
                        getNitAmonAvg(station.datas)
                      ),
                      getClassName(
                        'nitrogen_nitrite_mg_l_n',
                        getNitroNitAvg(station.datas)
                      ),
                      getClassName(
                        'nitrogen_nitrate_mg_l_n',
                        getNitroNitrateAvg(station.datas)
                      ),
                      getClassName(
                        'phosphate_mg_l_PO43',
                        getPhosAvg(station.datas)
                      )
                    )
                  "
                  v-text="
                    getPhysChemStatus(
                      getClassName('ph', getPhAvg(station.datas)),
                      getClassName(
                        'specific_conduct_ms_cm',
                        getConductAvg(station.datas)
                      ),
                      getClassName(
                        'total_alkalinity_mg_l_ca_co3',
                        getAlcAvg(station.datas)
                      ),
                      getClassName(
                        'dissolved_oxygen_mg_l_o2',
                        getDysolvedAvg(station.datas)
                      ),
                      getClassName('bod5_mg_l_o2', getBodAvg(station.datas)),
                      getClassName(
                        'ip_cod_kmno4_mg_l_o2',
                        getCodAvg(station.datas)
                      ),
                      getClassName(
                        'nitrogen_ammonia_mg_l_n',
                        getNitAmonAvg(station.datas)
                      ),
                      getClassName(
                        'nitrogen_nitrite_mg_l_n',
                        getNitroNitAvg(station.datas)
                      ),
                      getClassName(
                        'nitrogen_nitrate_mg_l_n',
                        getNitroNitrateAvg(station.datas)
                      ),
                      getClassName(
                        'phosphate_mg_l_PO43',
                        getPhosAvg(station.datas)
                      )
                    )
                  "
                />
              </tr>
            </table>
          </div>
          <div
            v-else-if="tableType === 'per-months'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr>
                <th v-text="getMonthText()" />
                <th @click="sortBy('ph')" v-text="'pH'" />
                <th v-text="'Conductivity  [uS/cm]'" />
                <th v-text="'Alcalinity [mg/l CaCO3]'" />
                <th v-text="'Dissolved Oxygen [mg O2/l]'" />
                <th v-text="'BOD5 [mg O2/l]'" />
                <th v-text="'COD-KMnO4 [mg O2/l]'" />
                <th v-text="'NH4 [mg N/l]'" />
                <th v-text="'NO2 [mg N/l]'" />
                <th v-text="'NO3 [mg N/l]'" />
                <th v-text="'Phosphate PO4  [mg PO4/l]'" />
              </tr>
              <tr
                v-for="(value, key) in stations[form.station_id - 1].datas"
                :key="key"
                v-show="value.years.name === selectedYear"
              >
                <td
                  v-text="
                    language == 'MK'
                      ? value.months.name_mk
                      : language == 'AL'
                      ? value.months.name_al
                      : language == 'EN'
                      ? value.months.name
                      : value.months.name
                  "
                />
                <td
                  v-text="value.physical_props.ph"
                  :class="getClassName('ph', value.physical_props.ph)"
                />
                <td
                  v-text="value.physical_props.specific_conduct_ms_cm"
                  :class="
                    getClassName(
                      'specific_conduct_ms_cm',
                      value.physical_props.specific_conduct_ms_cm
                    )
                  "
                />
                <td
                  v-text="
                    value.alkalinities.total_alkalinity_mg_l_ca_co3.toFixed(0)
                  "
                  :class="
                    getClassName(
                      'total_alkalinity_mg_l_ca_co3',
                      value.alkalinities.total_alkalinity_mg_l_ca_co3
                    )
                  "
                />
                <td
                  v-text="
                    value.oxygen_regimes.dissolved_oxygen_mg_l_o2.toFixed(2)
                  "
                  :class="
                    getClassName(
                      'dissolved_oxygen_mg_l_o2',
                      value.oxygen_regimes.dissolved_oxygen_mg_l_o2
                    )
                  "
                />
                <td
                  v-text="value.oxygen_regimes.bod5_mg_l_o2.toFixed(2)"
                  :class="
                    getClassName(
                      'bod5_mg_l_o2',
                      value.oxygen_regimes.bod5_mg_l_o2
                    )
                  "
                />
                <td
                  v-text="value.oxygen_regimes.ip_cod_kmno4_mg_l_o2.toFixed(2)"
                  :class="
                    getClassName(
                      'ip_cod_kmno4_mg_l_o2',
                      value.oxygen_regimes.ip_cod_kmno4_mg_l_o2
                    )
                  "
                />
                <td
                  v-text="value.nutrients.nitrogen_ammonia_mg_l_n.toFixed(4)"
                  :class="
                    getClassName(
                      'nitrogen_ammonia_mg_l_n',
                      value.nutrients.nitrogen_ammonia_mg_l_n
                    )
                  "
                />
                <td
                  v-text="value.nutrients.nitrogen_nitrite_mg_l_n.toFixed(4)"
                  :class="
                    getClassName(
                      'nitrogen_nitrite_mg_l_n',
                      value.nutrients.nitrogen_nitrite_mg_l_n
                    )
                  "
                />
                <td
                  v-text="value.nutrients.nitrogen_nitrate_mg_l_n.toFixed(4)"
                  :class="
                    getClassName(
                      'nitrogen_nitrate_mg_l_n',
                      value.nutrients.nitrogen_nitrate_mg_l_n
                    )
                  "
                />
                <td
                  v-text="value.nutrients.phosphate_mg_l_PO43.toFixed(4)"
                  :class="
                    getClassName(
                      'phosphate_mg_l_PO43',
                      value.nutrients.phosphate_mg_l_PO43
                    )
                  "
                />
              </tr>
            </table>
          </div>
          <div v-else class="bar-chart__content flex-center">
            <span class="no-data">No data Available</span>
          </div>
        </div>

        <div v-else class="bar-chart">
          <div class="bar-chart__header">
            <div class="icon">
              <font-awesome-icon icon="chart-bar" size="1x" />
              <span class="station" v-text="selectedYear" />
              <span
                class="station"
                v-if="tableType == 'data-per-measure-point'"
                v-text="getMonthName(selectedMonth)"
              />
              <span
                v-if="tableType == 'per-months'"
                class="station"
                v-text="getStationName(form.station_id)"
              />
              <span
                v-else
                class="station"
                v-text="slotProps.inputData.contentTitle"
              />
            </div>
            <div class="menu">
              <div
                @click="changeTableType('data-per-measure-point')"
                class="menu__item"
                :class="{ active: tableType === 'data-per-measure-point' }"
              >
                {{ slotProps.inputData.contentLine }}
              </div>
              <div
                @click="changeTableType('per-months')"
                class="menu__item"
                :class="{ active: tableType === 'per-months' }"
              >
                {{ slotProps.inputData.buttonText }}
              </div>
              <div
                @click="changeTableType('average-yearly')"
                class="menu__item"
                :class="{ active: tableType === 'average-yearly' }"
              >
                {{ slotProps.inputData.buttonLink }}
              </div>
            </div>
          </div>
          <div
            v-if="tableType === 'data-per-measure-point'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr>
                <th v-text="getRiverText()" />
                <th v-text="getMPointText()" />
                <th v-text="getMonthText()" />
                <th v-text="'Fe [μg/L]'" />
                <th v-text="'Mn [μg/L]'" />
                <th v-text="'Pb [μg/L]'" />
                <th v-text="'Zn [μg/L]'" />
                <th v-text="'Cd [μg/L]'" />
                <th v-text="'Cr [μg/L]'" />
                <th v-text="'Cu [μg/L]'" />
              </tr>
              <tr
                v-for="(station, key) in stations"
                :key="key"
                @click="selectStation(station)"
                v-show="station.datas.length > 0"
              >
                <td
                  v-text="
                    language == 'MK'
                      ? station.rivers[0].name_mk
                      : language == 'EN'
                      ? station.rivers[0].name
                      : station.rivers[0].name
                  "
                />
                <td
                  v-text="
                    language == 'MK'
                      ? station.name_mk
                      : language == 'EN'
                      ? station.name
                      : station.name_al
                  "
                />
                <td
                  v-for="(value, mKey) in station.datas"
                  :key="'month' + mKey"
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="
                    language == 'MK'
                      ? value.months.name_mk
                      : language == 'AL'
                      ? value.months.name_al
                      : language == 'EN'
                      ? value.months.name
                      : value.months.name
                  "
                />
                <td
                  v-for="(value, ironK) in station.datas"
                  :key="'iron' + ironK"
                  :class="
                    getClassName(
                      'iron_fe_μg_l',
                      value.harmful_substances.iron_fe_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.iron_fe_μg_l"
                />
                <td
                  v-for="(value, mnK) in station.datas"
                  :key="'mn' + mnK"
                  :class="
                    getClassName(
                      'manganese_mn_μg_l',
                      value.harmful_substances.manganese_mn_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.manganese_mn_μg_l"
                />
                <td
                  v-for="(value, pbK) in station.datas"
                  :key="'pb' + pbK"
                  :class="
                    getClassName(
                      'lead_pb_μg_l',
                      value.harmful_substances.lead_pb_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.lead_pb_μg_l"
                />
                <td
                  v-for="(value, znK) in station.datas"
                  :key="'zn' + znK"
                  :class="
                    getClassName(
                      'zink_zn_μg_l',
                      value.harmful_substances.zink_zn_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.zink_zn_μg_l"
                />
                <td
                  v-for="(value, cdK) in station.datas"
                  :key="'cd' + cdK"
                  :class="
                    getClassName(
                      'cadmium_cd_μg_l',
                      value.harmful_substances.cadmium_cd_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.cadmium_cd_μg_l"
                />
                <td
                  v-for="(value, chrK) in station.datas"
                  :key="'chr' + chrK"
                  :class="
                    getClassName(
                      'chromium_total_VI_cr_μg_l',
                      value.harmful_substances.chromium_total_VI_cr_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.chromium_total_VI_cr_μg_l"
                />
                <td
                  v-for="(value, coK) in station.datas"
                  :key="'co' + coK"
                  :class="
                    getClassName(
                      'copper_cu_μg_l',
                      value.harmful_substances.copper_cu_μg_l
                    )
                  "
                  v-show="
                    value.years.name === selectedYear &&
                      value.months.name === selectedMonth
                  "
                  v-text="value.harmful_substances.copper_cu_μg_l"
                />
              </tr>
            </table>
          </div>
          <div
            v-else-if="tableType === 'average-yearly'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr>
                <th v-text="getRiverText()" />
                <th v-text="getMPointText()" />
                <th v-text="'Fe [μg/L]'" />
                <th v-text="'Mn [μg/L]'" />
                <th v-text="'Pb [μg/L]'" />
                <th v-text="'Zn [μg/L]'" />
                <th v-text="'Cd [μg/L]'" />
                <th v-text="'Cr [μg/L]'" />
                <th v-text="'Cu [μg/L]'" />
              </tr>
              <tr v-for="(station, key) in stations" :key="key">
                <td
                  v-text="
                    language == 'MK'
                      ? station.rivers[0].name_mk
                      : language == 'EN'
                      ? station.rivers[0].name
                      : station.rivers[0].name
                  "
                />
                <td
                  v-text="
                    language == 'MK'
                      ? station.name_mk
                      : language == 'EN'
                      ? station.name
                      : station.name_al
                  "
                />
                <td
                  v-text="getIronAvg(station.datas)"
                  :class="
                    getClassName('iron_fe_μg_l', getIronAvg(station.datas))
                  "
                />
                <td
                  v-text="getMngAvg(station.datas)"
                  :class="
                    getClassName('manganese_mn_μg_l', getMngAvg(station.datas))
                  "
                />
                <td
                  v-text="getPbAvg(station.datas)"
                  :class="getClassName('lead_pb_μg_l', getPbAvg(station.datas))"
                />
                <td
                  v-text="getZnAvg(station.datas)"
                  :class="getClassName('zink_zn_μg_l', getZnAvg(station.datas))"
                />
                <td
                  v-text="getCdAvg(station.datas)"
                  :class="
                    getClassName('cadmium_cd_μg_l', getCdAvg(station.datas))
                  "
                />
                <td
                  v-text="getChromAvg(station.datas)"
                  :class="
                    getClassName(
                      'chromium_total_VI_cr_μg_l',
                      getChromAvg(station.datas)
                    )
                  "
                />
                <td
                  v-text="getCuAvg(station.datas)"
                  :class="
                    getClassName('copper_cu_μg_l', getCuAvg(station.datas))
                  "
                />
              </tr>
            </table>
          </div>
          <div
            v-else-if="tableType === 'per-months'"
            class="bar-chart__content"
          >
            <table class="flexi-table">
              <tr>
                <th v-text="getMonthText()" />
                <th v-text="'Fe [μg/L]'" />
                <th v-text="'Mn [μg/L]'" />
                <th v-text="'Pb [μg/L]'" />
                <th v-text="'Zn [μg/L]'" />
                <th v-text="'Cd [μg/L]'" />
                <th v-text="'Cr [μg/L]'" />
                <th v-text="'Cu [μg/L]'" />
              </tr>
              <tr
                v-for="(value, key) in stations[form.station_id - 1].datas"
                :key="key"
                v-show="value.years.name === selectedYear"
              >
                <td
                  v-text="
                    language == 'MK'
                      ? value.months.name_mk
                      : language == 'AL'
                      ? value.months.name_al
                      : language == 'EN'
                      ? value.months.name
                      : value.months.name
                  "
                />
                <td
                  v-text="value.harmful_substances.iron_fe_μg_l"
                  :class="
                    getClassName(
                      'iron_fe_μg_l',
                      value.harmful_substances.iron_fe_μg_l
                    )
                  "
                />
                <td
                  v-text="value.harmful_substances.manganese_mn_μg_l"
                  :class="
                    getClassName(
                      'manganese_mn_μg_l',
                      value.harmful_substances.manganese_mn_μg_l
                    )
                  "
                />
                <td
                  v-text="value.harmful_substances.lead_pb_μg_l"
                  :class="
                    getClassName(
                      'lead_pb_μg_l',
                      value.harmful_substances.lead_pb_μg_l
                    )
                  "
                />
                <td
                  v-text="value.harmful_substances.zink_zn_μg_l"
                  :class="
                    getClassName(
                      'zink_zn_μg_l',
                      value.harmful_substances.zink_zn_μg_l
                    )
                  "
                />
                <td
                  v-text="value.harmful_substances.cadmium_cd_μg_l"
                  :class="
                    getClassName(
                      'cadmium_cd_μg_l',
                      value.harmful_substances.cadmium_cd_μg_l
                    )
                  "
                />
                <td
                  v-text="value.harmful_substances.chromium_total_VI_cr_μg_l"
                  :class="
                    getClassName(
                      'chromium_total_VI_cr_μg_l',
                      value.harmful_substances.chromium_total_VI_cr_μg_l
                    )
                  "
                />
                <td
                  v-text="value.harmful_substances.copper_cu_μg_l"
                  :class="
                    getClassName(
                      'copper_cu_μg_l',
                      value.harmful_substances.copper_cu_μg_l
                    )
                  "
                />
              </tr>
            </table>
          </div>
          <div v-else class="bar-chart__content flex-center">
            <span class="no-data">No data Available</span>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- First Section // Charts Section -->
    <flexi-wrapper
      v-if="inputData.homePage && logoReady"
      :inputData="inputData.homePage.sections[0]"
      @button-clicked="goToRoute"
    >
      <template v-slot:content="slotProps">
        <div class="selector-wrapper">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            <label class="title">{{ slotProps.inputData.headerTitle }}</label>
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerSubtitle
            }}</label>
            <v-select
              v-if="sortedYears"
              v-model="form.year"
              :options="sortedYears"
              :reduce="name => name.id"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerDescription
            }}</label>
            <v-select
              v-if="stations"
              v-model="form.station_id"
              :options="stations"
              :reduce="name => name.id"
              :selectable="option => chartType == 'stations'"
              :class="{ 'disabled-selector': chartType == 'average' }"
              :label="
                language == 'MK'
                  ? 'name_mk'
                  : language == 'EN'
                  ? 'name'
                  : 'name'
              "
              class="chart-chooser"
            >
            </v-select>
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonText
            }}</label>
            <v-select
              v-if="categories"
              v-model="form.category"
              :options="categories"
              :reduce="name => name.ident"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonLink
            }}</label>
            <v-select
              v-if="tableFields"
              v-model="form.value"
              :options="computedParameters"
              :reduce="title => title.sortField"
              label="title"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{ slotProps.inputData.subTitle }}</label>
            <div class="legend">
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getHighText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getGoodText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getMediumText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getPoorText()" />
              </div>
              <div class="legend__item">
                <div class="color" />
                <div class="description" v-text="getBadText()" />
              </div>
            </div>
          </div>
        </div>
        <div class="bar-chart">
          <div class="bar-chart__header">
            <div class="icon">
              <font-awesome-icon icon="chart-bar" size="1x" />
              <span class="station" v-text="getYearName(form.year)" />
              <span
                v-if="chartType === 'stations'"
                class="station"
                v-text="getStationName(form.station_id)"
              />
              <span
                v-else
                class="station"
                v-text="slotProps.inputData.buttonText"
              />
              <span class="station" v-text="form.value_name" />
            </div>
            <div class="menu">
              <div @click="changeChartType('stations')" class="menu__item">
                {{ slotProps.inputData.contentTitle }}
              </div>
              <div @click="changeChartType('average')" class="menu__item">
                {{ slotProps.inputData.contentLine }}
              </div>
            </div>
          </div>
          <div
            v-if="
              chartType === 'stations' &&
                computedChartData.datasets[0].data.length > 0
            "
            class="bar-chart__content"
            :class="{ 'flex-center': !showChart }"
          >
            <BarChart
              v-if="showChart"
              :height="450"
              :chartData="computedChartData"
              :chartOptions="computedChartOptions"
            />
            <div v-else class="spinner">
              <semipolar-spinner
                :animation-duration="1500"
                :size="55"
                color="#fff"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
              />
            </div>
          </div>
          <div
            v-else-if="'average' && computedChartData.datasets[0].data.length"
            class="bar-chart__content"
            :class="{ 'flex-center': !showChart }"
          >
            <BarChart
              v-if="showChart"
              :height="450"
              :chartData="computedChartData"
              :chartOptions="computedChartOptions"
            />
            <div v-else class="spinner">
              <semipolar-spinner
                :animation-duration="1500"
                :size="55"
                color="#fff"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
              />
            </div>
          </div>
          <div
            v-else-if="computedChartData.datasets[0].data.length === 0"
            class="bar-chart__content flex-center"
          >
            <span class="no-data">No data Available</span>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Reports Section -->
    <flexi-wrapper
      v-if="inputData.homePage && logoReady"
      :inputData="inputData.homePage.sections[2]"
      @button-clicked="goToRoute"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <div
          class="header__description"
          v-html="slotProps.inputData.description"
        />
      </template>
      <template v-slot:content>
        <div class="reports-wrapper">
          <div class="reports-wrapper__menu">
            <div>
              <font-awesome-icon icon="grip-horizontal" size="1x" />
              <label
                class="title"
                v-text="inputData.homePage.sections[2].subTitle"
              />
            </div>
            <div
              v-for="(value, key) in reports_categories"
              :key="key"
              class="item"
              :class="{ active: selectedCategoryId === value.id }"
              v-text="
                language == 'MK'
                  ? value.name_mk
                  : language == 'AL'
                  ? value.name_al
                  : language == 'EN'
                  ? value.name
                  : value.name
              "
              @click="changeReportsCategory(value.id)"
            />
          </div>
          <div
            class="reports-wrapper__item"
            v-for="(report, key) in reports"
            :key="key"
            v-show="report.reports_categories_id === selectedCategoryId"
          >
            <a class="icon" :href="report.url" target="__blank" download>
              <font-awesome-icon icon="cloud-download-alt" size="1x" />
              <div
                class="item item-text"
                v-text="
                  language == 'MK'
                    ? 'Преземи'
                    : language == 'AL'
                    ? 'Shkarko'
                    : language == 'EN'
                    ? 'Download'
                    : 'Download'
                "
              />
            </a>
            <a class="item" :href="report.url" target="__blank" download>
              <div class="item">{{ report.name }}</div>
            </a>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Disclaimer Section -->
    <div v-if="logoReady" id="disclaimer-section">
      <div class="disclaimer">
        <div class="disclaimer__left">
          <div class="item">
            <span class="mr-5" v-text="inputData.homePage.sections[3].title" />
            <span>
              <a
                href="https://uhmr.gov.mk"
                target="_blank"
                v-text="inputData.homePage.sections[3].subTitle"
              />
            </span>
          </div>
          <div class="item">
            <span
              class="mr-5"
              v-text="inputData.homePage.sections[3].contentTitle"
            />
            <span>
              <a
                href="https://iks.edu.mk/en/home/"
                target="_blank"
                v-text="inputData.homePage.sections[3].contentLine"
              />
            </span>
          </div>
        </div>
        <div class="disclaimer__right">
          <div class="item">
            <span
              class="mr-5"
              v-text="inputData.homePage.sections[3].buttonText"
            />
            <span>
              <a href="https://uhmr.gov.mk" target="_blank">www.uhmr.gov.mk</a>
            </span>
          </div>
          <div class="item">
            <span
              class="mr-5"
              v-text="inputData.homePage.sections[3].buttonLink"
            />
            <span>
              <a href="https://iks.edu.mk/en/home/" target="_blank"
                >www.iks.edu.mk</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Section -->
    <div v-if="logoReady" id="footer-section">
      <div class="footer">
        <span class="footer__wrapper">
          © {{ year }} Developed and designed by
          <a class="action" href="https://akrinum.com" target="_blank"
            >Akrinum OÜ</a
          >
          . All Rights Reserved
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BarChart from "../components/BarChart.vue";
import tableFields from "../static/constants/table-fields";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "home",
  components: {
    BarChart,
    vSelect
  },
  watch: {
    form: {
      handler() {
        this.tableFields[0][this.form.category].filter(elem => {
          if (elem.sortField === this.form.value) {
            this.form.value_name = elem.title;
          }
        });
        this.showChart = false;
        this.chartType === "stations"
          ? this.updateStationsCharts(this.form)
          : this.updateAvgCharts(this.form);
        setTimeout(() => {
          this.showChart = true;
        }, 1000);
      },
      deep: true,
      immediate: false
    },
    windowWidth: {
      handler(width) {
        this.isMobile = width < 720;
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      "chartData",
      "chartOptions",
      "categories",
      "inputData",
      "logoReady",
      "playerReady",
      "stations",
      "years",
      "year",
      "months",
      "reports",
      "reports_categories",
      "language"
    ]),
    computedParameters() {
      /* eslint-disable */
      this.form.value = this.tableFields[0][this.form.category][0].sortField;
      return this.tableFields[0][this.form.category];
    },
    computedChartData() {
      return this.chartData;
    },
    computedChartOptions() {
      return this.chartOptions;
    },
    sortedYears() {
      return this.years.sort((a, b) => b.name - a.name);
    },
  },
  data() {
    return {
      chartType: "stations",
      tableType: "data-per-measure-point",
      showChart: true,
      topCardready: false,
      hidebarReady: false,
      isMobile: false,
      windowWidth: window.outerWidth,
      options: {
        controls: false,
        muted: true,
        autoplay: true,
        responsive: true,
        sidedock: false,
        background: true,
      },
      selectedYear: "2021",
      selectedMonth: "December",
      form: {
        station_id: 1,
        category: "physical_props",
        value: "ph",
        value_name: "pH",
        year: 1,
      },
      sort: {
        column: "month_id",
        desc: false,
      },
      tableFields: tableFields,
      selectedCategoryId: 4,
      selectedTable: "Basic",
    };
  },
  methods: {
    ...mapActions(["setPlayer", "updateStationsCharts", "updateAvgCharts"]),
    changeSelectedTable(tableName) {
      this.selectedTable = tableName;
    },
    changeReportsCategory(id) {
      this.selectedCategoryId = id;
    },
    changeChartType(type) {
      this.showChart = false;
      type === "stations"
        ? this.updateStationsCharts(this.form)
        : this.updateAvgCharts(this.form);
      this.chartType = type;
      setTimeout(() => {
        this.showChart = true;
      }, 1000);
    },
    changeTableType(type) {
      this.tableType = type;
    },
    getMonthName(name) {
      var month;
      this.months.filter((elem) => {
        if (elem.name === name) {
          if (this.language == "EN") {
            month = elem.name;
          } else if (this.language == "AL") {
            month = elem.name_al;
          } else {
            month = elem.name_mk;
          }
        }
      });
      return month;
    },
    getStationName(id) {
      var station;
      this.stations.filter((elem) => {
        if (elem.id === id) {
          if (this.language == "EN") {
            station = elem.name;
          } else if (this.language == "AL") {
            station = elem.name_al;
          } else {
            station = elem.name_mk;
          }
        }
      });
      return station;
    },
    getYearName(id) {
      var year;
      this.years.filter((elem) => {
        if (elem.id === id) {
          year = elem.name;
        }
      });
      return year;
    },
    getPhAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.physical_props.ph) {
            arr.push(elem.physical_props.ph);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getConductAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.physical_props.specific_conduct_ms_cm) {
            arr.push(elem.physical_props.specific_conduct_ms_cm);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getAlcAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.alkalinities.total_alkalinity_mg_l_ca_co3) {
            arr.push(elem.alkalinities.total_alkalinity_mg_l_ca_co3);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(0);
      } else {
        return "No data";
      }
    },
    getDysolvedAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.oxygen_regimes.dissolved_oxygen_mg_l_o2) {
            arr.push(elem.oxygen_regimes.dissolved_oxygen_mg_l_o2);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getBodAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.oxygen_regimes.bod5_mg_l_o2) {
            arr.push(elem.oxygen_regimes.bod5_mg_l_o2);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getCodAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.oxygen_regimes.ip_cod_kmno4_mg_l_o2) {
            arr.push(elem.oxygen_regimes.ip_cod_kmno4_mg_l_o2);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getNitAmonAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.nutrients.nitrogen_ammonia_mg_l_n) {
            arr.push(elem.nutrients.nitrogen_ammonia_mg_l_n);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(4);
      } else {
        return "No data";
      }
    },
    getNitroNitAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.nutrients.nitrogen_nitrite_mg_l_n) {
            arr.push(elem.nutrients.nitrogen_nitrite_mg_l_n);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(4);
      } else {
        return "No data";
      }
    },
    getNitroNitrateAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.nutrients.nitrogen_nitrate_mg_l_n) {
            arr.push(elem.nutrients.nitrogen_nitrate_mg_l_n);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(4);
      } else {
        return "No data";
      }
    },
    getPhosAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.nutrients.phosphate_mg_l_PO43) {
            arr.push(elem.nutrients.phosphate_mg_l_PO43);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(4);
      } else {
        return "No data";
      }
    },
    getIronAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.iron_fe_μg_l) {
            arr.push(elem.harmful_substances.iron_fe_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getMngAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.manganese_mn_μg_l) {
            arr.push(elem.harmful_substances.manganese_mn_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getPbAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.lead_pb_μg_l) {
            arr.push(elem.harmful_substances.lead_pb_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getZnAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.zink_zn_μg_l) {
            arr.push(elem.harmful_substances.zink_zn_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getCdAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.cadmium_cd_μg_l) {
            arr.push(elem.harmful_substances.cadmium_cd_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getChromAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.chromium_total_VI_cr_μg_l) {
            arr.push(elem.harmful_substances.chromium_total_VI_cr_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getCuAvg(data) {
      var arr = [];
      data.filter((elem) => {
        if (elem.years.name === this.selectedYear) {
          if (elem.harmful_substances.copper_cu_μg_l) {
            arr.push(elem.harmful_substances.copper_cu_μg_l);
          }
        }
      });
      if (arr.length > 0) {
        return (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(2);
      } else {
        return "No data";
      }
    },
    getPhysChemStatusClass(
      var1,
      var2,
      var3,
      var4,
      var5,
      var6,
      var7,
      var8,
      var9,
      var10
    ) {
      var high = "high";
      var good = "good";
      var medium = "medium";
      var poor = "poor";
      var bad = "bad";

      let array = [var1, var2, var3, var4, var5, var6, var7, var8, var9, var10];

      if (array.find((element) => element === bad)) {
        return bad;
      }

      if (array.find((element) => element === poor)) {
        return poor;
      }

      if (array.find((element) => element === medium)) {
        return medium;
      }

      if (array.find((element) => element === good)) {
        return good;
      }

      if (array.find((element) => element === high)) {
        return high;
      }
    },
    getPhysChemStatus(
      var1,
      var2,
      var3,
      var4,
      var5,
      var6,
      var7,
      var8,
      var9,
      var10
    ) {
      var high = "high";
      var good = "good";
      var medium = "medium";
      var poor = "poor";
      var bad = "bad";

      var high_mk = "Одличен";
      var good_mk = "Добар";
      var medium_mk = "Прифатлив";
      var poor_mk = "Слаб";
      var bad_mk = "Лош";

      var high_al = "e lartë";
      var good_al = "Mirë";
      var medium_al = "Mesatare";
      var poor_al = "e varfër";
      var bad_al = "Keq";

      let array = [var1, var2, var3, var4, var5, var6, var7, var8, var9, var10];

      if (array.find((element) => element === bad)) {
        if (this.language === "EN") {
          return bad.toUpperCase();
        } else if (this.language === "MK") {
          return bad_mk.toUpperCase();
        } else {
          return bad_al.toUpperCase();
        }
      }

      if (array.find((element) => element === poor)) {
        if (this.language === "EN") {
          return poor.toUpperCase();
        } else if (this.language === "MK") {
          return poor_mk.toUpperCase();
        } else {
          return poor_al.toUpperCase();
        }
      }

      if (array.find((element) => element === medium)) {
        if (this.language === "EN") {
          return medium.toUpperCase();
        } else if (this.language === "MK") {
          return medium_mk.toUpperCase();
        } else {
          return medium_al.toUpperCase();
        }
      }

      if (array.find((element) => element === good)) {
        if (this.language === "EN") {
          return good.toUpperCase();
        } else if (this.language === "MK") {
          return good_mk.toUpperCase();
        } else {
          return good_al.toUpperCase();
        }
      }

      if (array.find((element) => element === high)) {
        if (this.language === "EN") {
          return high.toUpperCase();
        } else if (this.language === "MK") {
          return high_mk.toUpperCase();
        } else {
          return high_al.toUpperCase();
        }
      }
    },
    getClassName(parameter, value) {
      var high = "high";
      var good = "good";
      var medium = "medium";
      var poor = "poor";
      var bad = "bad";

      if (parameter === "ph") {
        if (value >= 6.5 && value <= 8.5) {
          return high;
        } else if (value < 6.5 && value >= 6.3) {
          return good;
        } else if (value < 6.3 && value >= 6.0) {
          return medium;
        } else if (value < 6.0 && value >= 5.3) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "total_alkalinity_mg_l_ca_co3") {
        if (value > 200) {
          return high;
        } else if (value <= 200 && value >= 100) {
          return good;
        } else if (value < 100 && value >= 20) {
          return medium;
        } else if (value < 20 && value >= 10) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "specific_conduct_ms_cm") {
        if (value >= 0 && value <= 500) {
          return high;
        } else if (value > 500 && value <= 700) {
          return good;
        } else if (value > 700 && value <= 1000) {
          return medium;
        } else if (value > 1000 && value <= 2000) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "dissolved_oxygen_mg_l_o2") {
        if (value >= 8.0) {
          return high;
        } else if (value < 8.0 && value >= 6) {
          return good;
        } else if (value < 6 && value >= 4) {
          return medium;
        } else if (value < 4 && value >= 2) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "bod5_mg_l_o2") {
        if (value <= 2) {
          return high;
        } else if (value > 2 && value <= 4) {
          return good;
        } else if (value > 4 && value <= 7) {
          return medium;
        } else if (value > 7 && value <= 15) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "ip_cod_kmno4_mg_l_o2") {
        if (value <= 2.5) {
          return high;
        } else if (value > 2.5 && value <= 4.2) {
          return good;
        } else if (value > 4.2 && value <= 6.7) {
          return medium;
        } else if (value > 6.7 && value <= 10) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "nitrogen_ammonia_mg_l_n") {
        if (value <= 0.2) {
          return high;
        } else if (value > 0.2 && value <= 0.8) {
          return good;
        } else if (value > 0.8 && value <= 3.9) {
          return medium;
        } else if (value > 3.9 && value <= 7.8) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "nitrogen_nitrite_mg_l_n") {
        if (value <= 0.003) {
          return high;
        } else if (value > 0.003 && value <= 0.1) {
          return good;
        } else if (value > 0.1 && value <= 0.3) {
          return medium;
        } else if (value > 0.3 && value <= 0.6) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "nitrogen_nitrate_mg_l_n") {
        if (value <= 1) {
          return high;
        } else if (value > 1 && value <= 3) {
          return good;
        } else if (value > 3 && value <= 5.6) {
          return medium;
        } else if (value > 5.6 && value <= 11.3) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "phosphate_mg_l_PO43") {
        if (value <= 0.14) {
          return high;
        } else if (value > 0.14 && value <= 0.2) {
          return good;
        } else if (value > 0.2 && value <= 0.4) {
          return medium;
        } else if (value > 0.4 && value <= 1.53) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "iron_fe_μg_l") {
        if (!value) {
          return;
        } else if (value <= 300) {
          return good;
        } else if (value > 300 && value <= 1000) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "manganese_mn_μg_l") {
        if (!value) {
          return;
        } else if (value <= 50) {
          return good;
        } else if (value > 50 && value <= 1000) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "lead_pb_μg_l") {
        if (!value) {
          return;
        } else if (value > 0 && value <= 10) {
          return good;
        } else if (value > 10 && value <= 30) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "zink_zn_μg_l") {
        if (!value) {
          return;
        } else if (value <= 100) {
          return good;
        } else if (value > 100 && value <= 200) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "cadmium_cd_μg_l") {
        if (!value) {
          return;
        } else if (value <= 0.1) {
          return good;
        } else if (value > 0.1 && value <= 10) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "chromium_total_VI_cr_μg_l") {
        if (!value) {
          return;
        } else if (value <= 50) {
          return good;
        } else if (value > 50 && value <= 100) {
          return poor;
        } else {
          return bad;
        }
      } else if (parameter === "copper_cu_μg_l") {
        if (!value) {
          return;
        } else if (value <= 10) {
          return good;
        } else if (value > 10 && value <= 50) {
          return poor;
        } else {
          return bad;
        }
      }
    },
    getHighGoodText() {
      if (this.language === "EN") {
        return "High/Good";
      } else if (this.language === "MK") {
        return "Одличен/Добар";
      } else {
        return "e lartë/Mirë";
      }
    },
    getMediumPoorText() {
      if (this.language === "EN") {
        return "Medium/Poor";
      } else if (this.language === "MK") {
        return "Прифатлив/Слаб";
      } else {
        return "Mesatare/e varfër";
      }
    },
    getHighText() {
      if (this.language === "EN") {
        return "High";
      } else if (this.language === "MK") {
        return "Одличен";
      } else {
        return "e lartë";
      }
    },
    getGoodText() {
      if (this.language === "EN") {
        return "Good";
      } else if (this.language === "MK") {
        return "Добар";
      } else {
        return "Mirë";
      }
    },
    getMediumText() {
      if (this.language === "EN") {
        return "Medium";
      } else if (this.language === "MK") {
        return "Прифатлив";
      } else {
        return "Mesatare";
      }
    },
    getPoorText() {
      if (this.language === "EN") {
        return "Poor";
      } else if (this.language === "MK") {
        return "Слаб";
      } else {
        return "e varfër";
      }
    },
    getBadText() {
      if (this.language === "EN") {
        return "Bad";
      } else if (this.language === "MK") {
        return "Лош";
      } else {
        return "Keq";
      }
    },
    getRiverText() {
      if (this.language === "EN") {
        return "River";
      } else if (this.language === "MK") {
        return "Река";
      } else {
        return "Lumi";
      }
    },
    getMPointText() {
      if (this.language === "EN") {
        return "M. Point";
      } else if (this.language === "MK") {
        return "Мерно место";
      } else {
        return "Pika e matjes";
      }
    },
    getMonthText() {
      if (this.language === "EN") {
        return "Month";
      } else if (this.language === "MK") {
        return "Месец";
      } else {
        return "Muaj";
      }
    },
    selectStation(station) {
      this.form.station_id = station.id;
      this.changeTableType("per-months");
    },
    sortBy(column) {
      this.sort.column = column;
      this.sort.desc = !this.sort.desc;
    },
    onReady() {
      this.setPlayer(true).then((res) => {
        setTimeout(() => {
          this.hidebarReady = true;
        }, 50);
        setTimeout(() => {
          this.topCardready = true;
        }, 820);

        setTimeout(() => {
          this.animateTopCard();
        }, 820);
      });
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.outerWidth;
    };
  },
};
</script>

<style lang="scss">
// @import "~@schnapsterdog/flexi-vue/src/assets/scss/parts/_media-mixins";
</style>
